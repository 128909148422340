* {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Hide spin buttons in Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.top-navbar {
    width: 100%;
    height: 80px;
    position: fixed;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    /* box-shadow: 0px 15px 10px -15px #111; */
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);

}

.top-navbar .navbar {
    width: 100%;
    height: 100px;
    background: #FBFBFB;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.top-navbar .nav-content {
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: none;
}

.top-navbar .nav-content .logo {
    width: 80%;
}

.top-navbar .nav-ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 55px;
    margin-bottom: 0;
    list-style-type: none;
    padding: 0;
}

.top-navbar .nav-ul .nav-link {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}

.top-navbar .nav-ul .nav-link:hover {
    color: #00DF67;
}


.top-navbar .btn-find-work {
    border-radius: 50px;
    border: none;
}



.top-navbar .btn-find-work .btn-find-link {
    text-decoration: none;
}

.top-navbar .btn-find-work .btn-inner {
    padding: 8px 25px;
    border-radius: 50px;
    font-size: 18px;
    font-weight: 800;
    color: #000;
}

.top-navbar .btn-find-work .btn-inner:hover {
    background: #00DF67;
    color: #FFFFFF;
}

.top-navbar .topnav-pills {
    background: #E7E7E7;
    padding: 4px;
    border-radius: 30px;
}

.top-navbar .topnav-pills .nav-link {
    padding: 5px 20px;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 700;
    color: #000;
}

.top-navbar .topnav-pills .nav-link.active {
    background: #4FBF67;
    color: #FFFFFF;
}

.top-navbar .topnav-pills .nav-link.active:hover {
    color: #FFFFFF;
}

.top-navbar .topnav-pills .nav-link:hover {
    color: #000;
}



.homepage .banner-section {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}


.homepage .banner-section .banner-img {
    width: 100%;
    height: auto;
}

.homepage .banner-section .hs-banner-img {
    margin: 109px 0 0 0;
    /* width: 593px;
    height: 355px; */
}

.homepage .banner-section .bc-left {
    padding-left: 60px;
    padding-top: 60px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 10px;
}

.homepage .banner-section .h1-text-1 {
    font-size: 60px;
    font-weight: 800;
    color: #000000;
}

.homepage .banner-section .h1-text-1 span {
    color: #00DF67;
}

.homepage .banner-section .p-text-1 {
    font-size: 20px;
    font-weight: 400;
    color: #000000;
}

.homepage .banner-section .btn-gs {
    background: #000000;
    border: none;
    padding: 12px 30px;
    border-radius: 50px;
    font-size: 18px;
    font-weight: 700;
    color: #FFFFFF;
}

.homepage .banner-section .bc-btn-sec {
    display: flex;
    align-items: center;
    gap: 20px;
}

.homepage .banner-section .btn-ps {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

}

.homepage .banner-section .btn-tool-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.homepage .banner-section .p-cs-text {
    margin-top: 10px;
    width: 80%;
    padding: 4px;
    background: #000000;
    color: #FFFFFF;
    font-size: 10px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    position: relative;
}

.homepage .banner-section .p-cs-text::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent #000000 transparent;
}

.homepage .banner-section .app-btn {
    border: none !important;
    background: none !important;

}

.homepage .banner-section .bc-right {
    width: 100%;
    height: 100%;
    padding: 0 40px;
    display: flex;
    justify-content: center;
}

.marquee-wrapper {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    padding: 40px 0px;
}

.marquee {
    display: inline-block;
    animation: scroll 20s linear infinite;
}

.marquee img {
    margin: 0px 40px;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-50%);
    }
}

.homepage .about {
    padding: 60px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.homepage .about-content {
    width: 90%;
}

.homepage .t-head {
    padding: 30px 0px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 40px;
}

.homepage .t-head .h2-text-1 {
    font-size: 38px;
    text-align: start;
    font-weight: 700;
    color: #000000;
    margin-bottom: 0;
}

.homepage .t-head .p-text-2 {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 0;
}

.homepage .about .about-card {
    margin-top: 60px;
    padding: 0px 60px;
    border-radius: 40px;
    background: #F3F3F3;
    border: none;
}

.homepage .about .ac-left {
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 20px;
}

.homepage .about .h3-text-1 {
    font-size: 40px;
    font-weight: 700;
    color: #000000;
    margin-bottom: 0;
}

.homepage .about .p-text-3 {
    font-size: 18px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 0;
}

.homepage .about .ac-right {
    padding: 50px 0;
    height: 100%;
    position: relative;
}

.homepage .about .about-h-img {
    width: 70%;
    position: absolute;
    top: -70%;
    left: 15%;
}

.homepage .about .about-img {
    width: 13%;
    position: absolute;
    bottom: 97px;
    right: 3px;
}

.find-work .about .about-img {
    width: 13%;
    position: absolute;
    bottom: 127px;
    right: 3px;
}

/* grid card  */
.homepage .grid-card {
    padding: 60px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.homepage .grid-card .grid-card-section {
    width: 90%;
}

.homepage .grid-card-row {
    margin-top: 10px;
}



.homepage .g-card {
    height: 100%;
    padding: 40px;
    border-radius: 40px;
    border: none;
    background: #F3F3F3;
    transition: all 0.3s ease;
    border-bottom: 5px solid #F3F3F3;

}

.homepage .g-card:hover {
    border-bottom: 5px solid #00FF75;
    ;
}

.homepage .g-card .g-card-left {
    margin-top: 10px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    gap: 30px;
}

.homepage .g-card .g-card-right {
    width: 50%;
    height: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
}

.homepage .g-card .g-card-right img {
    width: 70%;
    height: auto;
}

.homepage .g-card-h-text {
    font-size: 30px;
    font-weight: 700;
    color: #000000;
}

.homepage .g-card-p {
    font-size: 16px;
    font-weight: 400;
    color: #878787;
}

.homepage .grid-card .g-card-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}



.homepage .how-it-works {
    padding: 60px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.homepage .how-it-works-content {
    width: 90%;
}

.homepage .hw-card-section {
    margin-top: 50px;
}

.homepage .hw-card {
    padding: 20px 35px;
    border-radius: 40px;
    background: #F3F3F3;
    border: none;
    padding-bottom: 25px;
    /* height: 100% !important; */
    min-height: 300px;
}

.homepage .h3-text-2 {
    font-size: 34px;
    font-weight: 700;
    color: #000000;
    margin-bottom: 0;
}

.homepage .how-it-works .p-text-4 {
    font-size: 20px;
    font-weight: 400;
    color: #878787;
}

.homepage .hw-card-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.homepage .how-it-works .hc-left {
    width: 70%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 15px;
}

.homepage .how-it-works .hc-right {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
}

.homepage .how-it-works .hc-search-img {
    width: 100%;
    height: auto;
}

.homepage .hw-bg {
    background: none;
    padding-bottom: 5px;
    border-top-left-radius: 200px;
    border-top-right-radius: 200px;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    transition: all 0.3s ease;
}

.homepage .hw-bg:hover {
    background: #00DF67;
    padding-bottom: 5px;
    border-top-left-radius: 200px;
    border-top-right-radius: 200px;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
}

.homepage .benefits {
    padding: 60px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.homepage .benefits-content {
    width: 90%;
}

.homepage .benefits-card-section {
    margin-top: 50px;
}

.homepage .bc-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 25px 0px;
}

.homepage .benefits .b-card-bg {
    background: #F3F3F3;
    border-radius: 40px;
    transition: all 0.3s ease;

}

.homepage .benefits .b-card {
    border-radius: 40px;
    background: #00FF75;
    border: none;
}

.homepage .benefits .b-card-black {
    transition: all 0.3s ease;
    background: #F3F3F3;
    color: black;
}

.homepage .benefits .b-card-bg .b-card:hover {
    background: #00FF75;

}

.homepage .benefits .b-card-bg:hover {
    background: #00FF75;

}

.homepage .benefits .h2-text-2 {
    font-size: 30px;
    font-weight: 700;
    color: #000000;
    margin-bottom: 0;
}

.homepage .benefits .p-text-5 {
    width: 70%;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 0;
    text-align: center;
}

.homepage .benefits .b-card-black .h2-text-2 {
    color: #000000;

}

.homepage .benefits .b-card-black .p-text-5 {
    color: #000000;
}

.homepage .benefits .b-card-bg .b-card-black:hover .h2-text-2,
.homepage .benefits .b-card-bg .b-card-black:hover .p-text-5 {
    color: #000000 !important;

}

.homepage .why-choose {
    padding: 60px 0px 100px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.homepage .why-choose-content {
    width: 90%;
}

.homepage .wc-card-section {
    margin-top: 70px;
}

.homepage .why-choose .wc-card {
    padding: 30px 0px;
    border-radius: 30px;
    background: #F3F3F3;
    border: none;
    transition: all 0.3s ease;
}

.homepage .why-choose .wc-card:hover {
    background: #00DF67;
}

.homepage .why-choose .wc-card-pa:hover {
    box-shadow: none;
}


.homepage .why-choose .wc-card:hover .h4-text-1 {
    color: #FFFFFF;
}

.homepage .why-choose .wc-card:hover .p-text-6 {
    color: #000000;
}

.homepage .why-choose .wc-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.homepage .why-choose .h4-text-1 {
    font-size: 28px;
    font-weight: 700;
    color: #000000;
    margin-bottom: 0;
}

.homepage .why-choose .p-text-6 {
    font-size: 14.5px;
    font-weight: 400;
    color: #878787;
    margin-bottom: 0;
}

.homepage .why-choose .wc-card-pa {
    box-shadow: 0 -20px 20px -15px #333;
}

.homepage .why-choose .wc-middle {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.homepage .d-crewsor {
    padding: 60px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0;
    position: relative;
    z-index: 0;
}




.homepage .d-crewsor .bg-img {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    z-index: -2;
}

.homepage .dc-content {
    width: 90%;
}

.homepage .dc-center-t {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 20px;
    padding-bottom: 0;

}

.homepage .dc-content .h3-text-3 {
    font-size: 40px;
    font-weight: 700;
    /* color: #000000; */
    margin-bottom: 0;
}

.homepage .dc-content .p-text-7 {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 0;
    text-align: start;
}

.homepage .dc-card {
    padding: 30px 15px;
    border-radius: 40px;
    border: none;
    box-shadow:
        2px 4px 6px rgba(0, 0, 0, 0.1),
        -2px 4px 6px rgba(0, 0, 0, 0.1),
        0 4px 6px rgba(0, 0, 0, 0.1);
}


.homepage .dc-card .dc-card-head {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding-bottom: 20px;
    border-bottom: 2px solid #000000;
}

.homepage .dc-card .dch-name {
    height: 100%;
    display: flex;
    align-items: end;
    justify-content: start;
    gap: 15px;
}

.homepage .dc-card .h6-text-1 {
    font-size: 20;
    font-weight: 600;
    /* color: #000000; */
    margin-bottom: 5px;
}

.homepage .dc-card .dch-p {
    font-size: 20;
    font-weight: 400;
    margin-bottom: 0;
}

.homepage .dc-card-section {
    padding: 0;
}

.homepage .dc-card .avatar {
    width: 50%;
    height: auto;
}

.homepage .dc-card-body {
    padding: 15px 0px;
}

.homepage .dc-card-body .p-text-8 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
    line-height: 130%;
}

.homepage .dc-card-b {
    margin-top: 40px;
}



.homepage .dc-left {
    position: relative;
}

.homepage .dc-card-pa {
    position: absolute;
    top: 20%;
    right: 3%;
}

.homepage .dc-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.homepage .dc-r-card {
    padding: 15px 5px;
    border-radius: 30px;
    border: none;
}

.homepage .dc-r-card .dcr-head {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 0px 20px;
}

.homepage .dc-r-card .h2-text-3 {
    font-size: 26px;
    font-weight: 700;
    color: #000000;
    text-align: center !important;
    margin: 0;
}

.homepage .dc-r-card .cs-text {
    font-size: 18px;
    font-weight: 700;
    color: #00DF67;
}

.homepage .dc-btn-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.homepage .dc-r-card-body {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.homepage .dc-btn-div button {
    border: none;
    background: none;
}

.homepage .dc-btn-div .app-btn {
    border: none !important;
    background: none !important;
    position: relative;
    /* for the tooltip positioning */
    cursor: pointer;
}

.homepage .dc-btn-div .app-btn .tooltip {
    visibility: visible;
    width: 80px;
    font-size: 10px;
    font-weight: 500;
    color: #fff;
    background: #000000;
    text-align: center;
    padding: 5px;
    border-radius: 6px;
    position: absolute;
    top: 10px;
    left: -30%;
    transform: translateX(-50%);
    z-index: 1;
    opacity: 1;
    transition: opacity 0.3s;
}

.homepage .dc-btn-div .app-btn .tooltip::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #000000;
}






.homepage .faq {
    padding: 60px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
}

.homepage .faq-content {
    width: 90%;
}

.homepage .faq-accordion-section {
    margin-top: 40px;
}

.faq-btn-img {
    transition: transform 0.3s ease-in-out;
}

.faq-btn-img.rotate {
    transform: rotate(-180deg);
}

.homepage .accordion-item {
    margin-top: 20px;
    background: #DBDBDB;
    border-radius: 30px;
    padding: 0px 30px;
    transition: all 0.3s ease;
    border: none;
}

.accordion-item.active {
    /* background: #DBDBDB; */
}



.homepage .accordion-button {
    border-radius: 30px;
    padding: 30px 0px;
    background: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.homepage .accordion-button:focus {
    box-shadow: none;
}

.accordion-button:not(.collapsed) {
    background: none !important;
}



.accordion-button::after {
    display: none;
}

.accordion-item:first-of-type {
    border: none;
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important;
}

.accordion-item:last-of-type {
    border: none;
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
}

.homepage .faq .h3-text-4 {
    font-size: 26px;
    font-weight: 500;
    font-family: "Space Grotesk", sans-serif;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.homepage .faq .h3-text-4 span {
    font-size: 46px;
}

.homepage .accordion-body {
    border-top: 2px solid #000000;
    padding: 30px 0px;
}

.homepage .faq .accordion-p {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    color: #000000;
}

.homepage .faq .faq-btn-img {
    width: 40px;
}

.homepage .contact {
    padding: 40px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.homepage .contact .contact-p {
    font-size: 18px;
    font-weight: 400;
    color: #000
}

.homepage .contact-img {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;

}

.homepage .form-row {
    margin-top: 40px;
    padding: 30px;
    background: #F3F3F3;
    border-radius: 50px;
}

.homepage .contact .h2-text-1 {
    text-align: start;

}

.homepage .contact-content {
    width: 90%;
}

.homepage .form-div {
    margin-top: 40px;
    width: 100%;
    background: #F3F3F3;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.homepage .contact .c-error-p {
    font-size: 12px;
    font-weight: 400;
    color: red;
}

.homepage .f-radio {
    display: flex;
    justify-content: start;
    gap: 20px;
}

.form-check-input:checked {
    background-color: none !important;
    border: none !important;
    box-shadow: none !important;
    padding: 5px !important;
}

.form-check-input:focus {
    box-shadow: none !important;
}

.form-check-input[type=radio] {
    border: 2px solid #000000;
}

.form-check-input:checked[type=radio] {
    border: 2px solid #000000 !important;
    --bs-form-check-bg-image: url("../images/radio.svg") !important;
}

.homepage .contact .radio-label {
    font-weight: 400;
    color: #000000;
}

.homepage .contact .f-label {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
}

.homepage .contact .form-control {
    height: 60px;
    border: 1.5px solid #000000;
    border-radius: 15px;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
}

.homepage .contact .form-control:focus {
    box-shadow: none;
}

.homepage .contact .form-control::placeholder {
    font-size: 16px;
    font-weight: 500;
    color: #898989;
}

.homepage .contact .f-textarea {
    height: 200px;
    border-radius: 15px;
    border: 1.5px solid #000000;
    border-radius: 15px;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
}

.homepage .contact .btn-send {
    margin-top: 10px;
    height: 60px;
    width: 100%;
    border-radius: 50px;
    background: #191A23;
    border: none;
    font-size: 18px;
    font-weight: 700;
    color: #FFFFFF;
}

.homepage .contact .text-danger {
    font-size: 12px;
}

.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #191A23;

}

.footer .footer-content {
    width: 90%;
    padding: 20px 0px;
    background: #191A23;
    position: relative;
    z-index: 0;
}

.footer .footer-content .foot-bg-img {
    width: 30%;
    position: absolute;
    top: 0;
    right: 0;
    left: 20%;
    z-index: -1;
}

.footer .foot-head {
    width: 100%;
}

.footer .f-ul-div {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
}

.foot-ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    list-style-type: none;
    gap: 58px;
}

.footer .social-link {
    margin-top: 40px;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 20px;
}

.footer .social-link img {
    transition: all 0.3s ease;

}

.footer .social-link img:hover {
    filter: brightness(0) saturate(100%) invert(68%) sepia(53%) saturate(2423%) hue-rotate(96deg) brightness(95%) contrast(101%);
}

.footer .f-nav-link {
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    text-decoration: none;
    transition: all 0.2s ease;
}

.footer .f-nav-link:hover {
    color: #00DF67;
}

.footer .f-left {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 16px;
}

.footer .div-content-bg {
    background: #00FF75;
    border-radius: 5px;
    padding: 0px 5px;
}

.footer .div-content-bg p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 600;
    color: #000000;
}

.footer .email-p {
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    margin-bottom: 0;
}

.footer .a-mail {
    text-decoration: none !important;
    color: #FFFFFF !important;

}

.footer .phone-p {
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    margin-bottom: 0;
}

.footer .address-p {
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    margin-bottom: 0;
}

.footer .foot-form-div {

    display: flex;
    flex-direction: column;
    /* align-items: end; */
    justify-content: center;
    padding: 0 14px 0 116px;
}

.footer .form-row-foot {
    margin-top: 20px;
    width: 100%;
    padding: 40px 20px;
    background: #292a32;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer .f-form-label {
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    word-spacing: 2px;
}


.footer .foot-f-control {
    width: 100% !important;
    height: 70px;
    background: #191A23;
    border: none;
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    border-radius: 10px;
}

.footer .foot-f-control::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
}

.footer .foot-f-control:focus {
    box-shadow: none;
    background: #191A23;
    color: #FFFFFF;
    border-radius: 10px;
}

.footer .error-p {
    margin-top: 3px;
    font-size: 12px;
    font-size: 400;
    color: red;
}

.footer .btn-subscribe {
    width: 100%;
    height: 70px;
    border: none;
    border-radius: 15px;
    background: #00FF75;
    font-size: 16px;
    font-weight: 700;
    color: #000000;
}

.footer .vr-line {
    margin-top: 50px;
    height: 1px;
    width: 100%;
    background: #FFFFFF;
}

.footer .foot-last {
    margin-top: 20px;
}


.footer .copy-right {
    display: flex;
    justify-content: start;
    align-items: center;
}

.footer .copy-right .copy-p {
    margin-bottom: 2px;
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
}

.footer .copy-right .p-uen {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
}

.footer .link-pp {
    text-decoration: underline;
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
}

.footer .l-foot-bottom {
    display: flex;
    justify-content: end;
    align-items: end;
}


.footer .l-foot-bottom .fb-ul {
    list-style-type: none;
    display: flex;
    flex-direction: column !important;
    justify-content: end;
    align-items: end;
    gap: 5px;

}

.footer .l-foot-bottom .fb-ul .fb-nav-link {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    transition: all 0.3s ease;
}

.footer .l-foot-bottom .fb-ul .fb-nav-link:hover {
    color: #00DF67;
}














.load-more-btn {
    margin-top: 20px;
    border-radius: 50px;
    border: none;
    padding: 8px;
    background: #00DF67;
    transition: all 0.3s ease;
}

.load-more-btn .ld-in {
    background: #00DF67;
    padding: 8px 25px;
    border-radius: 50px;
    font-size: 18px;
    font-weight: 800;
    color: #FFFFFF;
}

.load-more-btn:hover {
    background: #00AC50;
}

.download-bg img {
    width: 100%;
    height: auto;
}

.b-card-black:hover {
    backgroun: #00DF67 !important;
}

.homepage .benefits .b-card-black:hover .h2-text-2 {
    color: #FFFFFF;


}

.homepage .benefits .b-card-black:hover .p-text-5 {
    color: #FFFFFF;
}


/* terms and condition  */

.t-and-c {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.t-and-c .tc-contents {
    width: 90%;
    padding-top: 140px;
    padding-bottom: 40px;
}

.t-and-c .t-h1 {
    font-size: 38px;
    font-weight: 700;
    color: #000;
}

.t-and-c .t-h1 span {
    color: #4FBF67;
}

.t-and-c .t-p {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}

.t-and-c .div-ul-con {
    margin-top: 30px;
}

.t-and-c .t-ul-div {
    margin-top: 25px;
}

.t-and-c .t-h2 {
    font-size: 22px;
    font-weight: 600;
    color: #000000;
}

.t-and-c .t-ul {
    margin-top: 20px;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 15px !important;
}

.t-and-c .t-ul .t-li {
    font-size: 15px;
    font-weight: 400;
    color: #000000;
}

.t-and-c .t-ul {
    margin-top: 20px;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.t-and-c .t-c-text {
    color: #24B500;
}

.t-and-c .tc-mail-a {
    color: #24B500;
    text-decoration: none;
    transition: all 0.3s ease;
}

.t-and-c .tc-mail-a:hover {
    font-weight: 500;
}


@media all and (max-width: 1050px) {
    .top-navbar .nav-ul {
        gap: 19px;
    }

}




@media all and (min-width: 992px) {
    .desktop-hide {
        display: none !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .tab-hide {
        display: none !important;
    }

    .top-navbar .mobile-toggle-div {
        display: flex;
        justify-content: end;
        gap: 80px;
        align-items: center;
    }



    .t-break {
        display: none !important;
    }


    .homepage .banner-section {
        height: auto;
        /* background-color: red; */
    }

    .homepage .banner-section .bc-right {
        padding: 0px;
        width: 100%;
        height: auto;
    }

    .homepage .banner-section .bc-left {
        padding: 0px 30px;
    }



    .homepage .t-head {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        gap: 20px;
    }

    .homepage .h3-text-2 {
        font-size: 24px;
    }

    .homepage .about .about-card {
        padding: 30px;
    }

    .homepage .how-it-works .p-text-4 {
        font-size: 18px;
    }

    .homepage .why-choose .h4-text-1 {
        font-size: 20px;
    }

    .homepage .why-choose .p-text-6 {
        font-size: 12px;
    }

    .homepage .dc-card-body .p-text-8 {
        font-size: 16px;
    }

    .homepage .dc-card .h6-text-1 {
        font-size: 16;
    }

    .homepage .dc-card .dch-p {
        font-size: 16;
    }

    .homepage .dc-card .avatar {
        width: 40%;
        height: auto;
        padding: 0;
    }

    .homepage .dc-card .dch-name {
        gap: 5px;
    }

    .homepage .dc-r-card .h2-text-3 {
        font-size: 22px;
    }

    .accordion-item {
        padding: 10px !important;
    }

    .homepage .faq .accordion-p {
        font-size: 16px;
    }

    .homepage .faq .h3-text-4 {
        font-size: 25px;
        gap: 10px;
    }

    .homepage .faq .h3-text-4 span {
        font-size: 35px;
    }

    .homepage .form-div {
        margin-top: 80px;
        background: #F3F3F3;
        padding: 30px 0px 30px 30px;
    }

    .footer .email-p {
        font-size: 18px;
    }

    .footer .phone-p {
        font-size: 18px;
    }

    .footer .address-p {
        font-size: 18px;
    }

    .footer .btn-subscribe {
        font-size: 16px;
    }

    .footer .f-form-label {
        font-size: 18px;
        text-align: center;
    }

    .homepage .about .about-img {
        width: 100%;
    }

    .homepage .about .about-h-img {
        width: 100%;
    }

    .menu-toggle {
        display: block;
    }

    .navbar-link {
        /* padding-top: 60px; */
        position: fixed;
        top: 100px;
        left: 0;
        right: 0;
        background: #FBFBFB;
        height: 0;
        overflow: hidden;
        transition: height 0.3s ease-in-out;
        z-index: 9;
    }

    .navbar-link.open {
        height: 100vh;
    }

    .nav-ul {
        margin-top: 40px;
        flex-direction: column;
        text-align: center;
    }

    .menu-toggle {
        height: 30px;
        width: 30px;
        border: none;
        background: none;
    }

    .menu-toggle img {
        height: 30px;
        width: 30px;
    }

    .top-navbar .logo {
        width: 50%;
    }

    .foot-ul {
        display: flex;
        align-items: start;
        justify-content: center;
        padding: 10px 0px;
    }

    .footer .f-ul-div {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
    }

    .homepage .hw-card {
        padding: 20px;
    }

    .homepage .hw-card-bottom {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
    }

    .homepage .hc-left {
        width: 100% !important;
    }

    .homepage .hw-card-bottom .hc-right {
        width: 100%;
        display: flex;
        align-items: end;
        justify-content: end;
    }

    .homepage .how-it-works .hc-search-img {
        width: 30%;
        height: auto;
    }

    .homepage .g-card {
        padding: 30px;
    }

    .homepage .benefits .p-text-5 {
        font-size: 16px;
        width: 100%;
    }

    .homepage .grid-card .g-card-h-text {
        font-size: 30px;
    }

    .homepage .grid-card .g-card-p {
        font-size: 18px;
    }

    .homepage .grid-card .g-card-body {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
    }

    .homepage .g-card .g-card-left {
        margin-top: 10px;
        width: 100%;
    }

    .homepage .g-card .g-card-right {
        width: 100%;

    }

    .homepage .g-card .g-card-right {
        display: flex;
        flex-direction: column;
        align-items: end;
        justify-content: end;
    }

    .homepage .g-card .g-card-right img {
        width: 20%;
    }

    .homepage .banner-section .hs-banner-img {
        margin: 30px;
        width: 100%;
        height: auto;
    }


}

@media(max-width:991px) {}

@media(max-width:767px) {
    .mobile-hide {
        display: none !important;
    }

    .break {
        display: none;
    }

    .top-navbar {
        height: 60px;
    }

    .top-navbar .mobile-toggle-div {
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 15px;
    }

    .top-navbar .topnav-pills .nav-link {
        padding: 5px 10px;
        border-radius: 30px;
        font-size: 12px;
        font-weight: 600;
    }

    .top-navbar .btn-find-work .btn-inner {
        font-size: 10px;
        padding: 5px 10px;
    }

    .homepage .banner-section {
        height: auto;
        /* background-color: red; */
    }

    .homepage .banner-section .bc-right {
        padding: 0px;
        width: 100%;
        height: auto;
    }


    .homepage .banner-section .bc-left {
        padding: 0px 30px;
    }

    .homepage .banner-section .h1-text-1 {
        font-size: 36px;
    }

    .homepage .banner-section .p-text-1 {
        font-size: 18px;
    }

    .homepage .banner-section .bc-btn-sec {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
    }

    .homepage .about .about-card {
        padding: 20px;
    }

    .homepage .about .h3-text-1 {
        font-size: 26px;
    }

    .homepage .about .p-text-3 {
        font-size: 16px;
    }

    .homepage .hw-card {
        padding: 20px;
    }

    .homepage .h3-text-2 {
        font-size: 24px;
    }

    .homepage .how-it-works .p-text-4 {
        font-size: 16px;
    }

    .homepage .t-head {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        gap: 20px;
    }

    .homepage .t-head .h2-text-1 {
        font-size: 26px;
        text-align: center;
    }

    .homepage .t-head .p-text-2 {
        font-size: 18px;
    }

    .homepage .benefits .h2-text-2 {
        font-size: 20px;
        text-align: center;
    }

    .homepage .benefits .p-text-5 {
        font-size: 14px;
        width: 95%;
    }

    .homepage .why-choose .h4-text-1 {
        font-size: 22px;
    }

    .homepage .dc-r-card {
        background: #F2F2F2;

    }

    .homepage .dc-r-card .dc-r-card-body {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .homepage .dc-r-card .h2-text-3 {
        font-size: 22px;
    }

    .homepage .dc-content .h3-text-3 {
        font-size: 22px;
    }

    .homepage .dc-content .p-text-7 {
        font-size: 14px;
    }

    .homepage .dc-card-pa {
        margin-top: 20px;
        position: static;
    }

    .homepage .dc-card-b {
        margin-top: 20px;
    }

    .accordion-item {
        padding: 5px 10px !important;
    }

    .homepage .faq .accordion-p {
        font-size: 16px;
    }

    .homepage .faq .h3-text-4 {
        font-size: 18px;
        gap: 10px;
    }

    .homepage .faq .h3-text-4 span {
        font-size: 20px;
    }

    .homepage .faq .faq-btn-img {
        width: 30px;
    }

    .homepage .form-div {
        padding: 15px;
        border-radius: 15px;
    }

    .homepage .form-div::before {
        display: none;
    }

    .footer .email-p {
        font-size: 16px;
    }

    .footer .phone-p {
        font-size: 16px;
    }

    .footer .address-p {
        font-size: 16px;
    }

    .footer .div-content-bg p {
        font-size: 14px;
    }

    .footer .footer-content {
        width: 90%;
        padding: 15px;
    }

    .menu-toggle {
        display: block;
    }

    .navbar-link {
        /* padding-top: 60px; */
        position: fixed;
        top: 55px;
        left: 0;
        right: 0;
        background: #FBFBFB;
        height: 0;
        overflow: hidden;
        transition: height 0.3s ease-in-out;
        z-index: 9;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
    }

    .navbar-link.open {
        height: 100vh;
    }

    .nav-ul {
        flex-direction: column;
        justify-content: start;
        padding: 40px 0px;
        margin-top: 40px;
    }

    .menu-toggle {
        height: 30px;
        width: 30px;
        border: none;
        background: none;
    }

    .menu-toggle img {
        height: 30px;
        width: 30px;
    }

    .top-navbar .logo {
        width: 70%;
    }

    .foot-ul {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        gap: 20px;
        padding: 0px;
    }

    .footer .social-link {
        margin-top: 20px;
        justify-content: start;
    }

    .footer .f-logo {
        width: 50%;
    }

    .homepage .about .ac-left {
        padding: 20px 0;
    }

    .homepage .about .ac-right {
        display: flex;
        justify-content: end;
        align-items: end;
        padding: 10px 0px;
    }

    .homepage .about .about-img {
        width: 25%;
        position: absolute;
        bottom: 228px;
        right: 3px;
    }

    .find-work .about .about-img {
        position: absolute;
        bottom: 254px;
    }

    .homepage .about .about-h-img {
        width: 50%;
        position: static;
    }

    .footer .f-ul-div {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
    }

    .footer .copy-right {
        flex-direction: column;
    }

    .footer .copy-right .copy-p {
        font-size: 14px;
    }


    .copy-right#copy-p {
        order: 2 !important;
    }

    .copy-right#privacy-policy {
        order: 1 !important;
    }

    .homepage .hw-card-bottom {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
    }

    .homepage .hw-card-bottom .hc-right {
        width: 100%;
        display: flex;
        align-items: end;
        justify-content: end;
    }

    .homepage .how-it-works .hc-search-img {
        width: 30%;
        height: auto;
    }

    .homepage .hw-card {
        border-radius: 25px;
    }

    .homepage .hw-bg {
        background: none;
        padding-bottom: 5px;
        border-top-left-radius: 200px;
        border-top-right-radius: 200px;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
        transition: all 0.3s ease;
    }

    .homepage .hw-bg:hover {
        background: #00DF67;
        padding-bottom: 5px;
        border-top-left-radius: 200px;
        border-top-right-radius: 200px;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
    }

    .homepage .how-it-works .hc-left {
        width: 100%;
    }




    .homepage .g-card {
        padding: 20px;
        border-radius: 25px;
    }

    .homepage .g-card-h-text {
        font-size: 26px;
    }

    .homepage .g-card-p {
        font-size: 16px;
    }

    .homepage .grid-card .g-card-body {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
    }

    .homepage .g-card .g-card-left {
        margin-top: 10px;
        width: 100%;
    }

    .homepage .g-card .g-card-right {
        width: 100%;

    }

    .homepage .g-card .g-card-right {
        display: flex;
        flex-direction: column;
        align-items: end;
        justify-content: end;
    }

    .homepage .g-card .g-card-right img {
        width: 20%;
    }

    .footer .l-foot-bottom {
        display: flex;
        justify-content: start;
        align-items: start;
    }


    .footer .l-foot-bottom .fb-ul {
        display: flex;
        flex-direction: column !important;
        justify-content: start;
        align-items: start;
        gap: 5px;
        padding: 0;
    }

    .footer .footer-content .foot-bg-img {
        width: 40%;
        position: absolute;
        top: 0;
        right: 0;
        left: 50%;
    }

    .homepage .form-row {
        padding: 20px;
    }


    .t-and-c .t-h1 {
        font-size: 24px;
    }

    .t-and-c .t-p {
        font-size: 15px;
    }

    .t-and-c .t-h2 {
        font-size: 20px;
    }

    .t-and-c .t-ul .t-li {
        font-size: 14px;
    }

    .footer .foot-form-div {
        margin-top: 10px;
        padding: 0;
    }

    .homepage .banner-section .app-btn .tooltip {
        visibility: visible !important;
    }

    .homepage .banner-section .hs-banner-img {
        margin: 30px;
        width: 100%;
        height: auto;
    }

    .homepage .dc-btn-div .app-btn {
        margin-top: 33px;
    }

    .homepage .dc-btn-div .app-btn .tooltip {
        top: -30px;
        left: 47%;
    }

    .homepage .dc-btn-div .app-btn .tooltip::after {
        top: 30px;
        left: 84%;
        transform: rotate(90deg);
    }



}

@media(max-width:360px) {
    .top-navbar .topnav-pills .nav-link {
        padding: 5px 10px;
        border-radius: 30px;
        font-size: 10px;
        font-weight: 600;
    }

}